
import { computed, defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import { useForm } from "vee-validate";
import IstQuestionAnsweringComponent from "@/views/psy/ist/component/IstQuestionAnsweringComponent.vue";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";

interface IST_Group{
  IST_Duration : number
  IST_Group_Id: string
  IST_Group_Name: string
  IST_Group_Type : string
  IST_Questions_Number: string
  completed: boolean
}

export default defineComponent({
  name: "ist-usr",
  components: {
    IstQuestionAnsweringComponent,
  },

  setup() {
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const groupCache = ref([] as Array<IST_Group>);
    var answerList = ref([] as Array<any>);

    const { handleSubmit } = useForm();

    const handleStep = handleSubmit(() => {
      // Mark the current step as completed
      groupCache.value[currentStepIndex.value].completed = true;

      // Move to the next step
      if (currentStepIndex.value < groupCache.value.length - 1) {
        currentStepIndex.value++;
      }
    });

    const previousStep = () => {
      if (currentStepIndex.value > 0) {
        currentStepIndex.value--;
      }
    };

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        confirmButtonText: "Ok, got it!",
      }).then(() => {
        window.location.reload();
      });
    };

    onMounted(() => {
      // Fetch your groups or any initial data here
      getIstGroup();
    });

    async function getIstGroup(){
      const mydatat = {
        Trigger: "R",
        Route: "IST_Groups",
        IST_Group_Id: ""
      };

      groupCache.value = [];
      await Services.PostDataXWWW(ApiService, "IST", mydatat, response => {
        groupCache.value = response.data.map(item => ({ ...item, completed: false }));
      }, err => {
        console.error(err);
      });
    }

    return {
      verticalWizardRef,
      currentStepIndex,
      groupCache,
      handleStep,
      previousStep,
      formSubmit,
    };
  },
});
