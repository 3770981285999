
import { Field } from "vee-validate";
import { defineComponent, onBeforeMount, ref, toRefs, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";

interface IST_Choice{
  ID: string
  Description: string
}

interface IST_Answer{
  ID: string
}

interface IST_Question_Answer{
  IST_Choice: Array<IST_Choice>
  IST_Order: number
  IST_Question: string
  IST_Question_Id: string
  IST_Answer: Array<IST_Answer>
  
  Count_Down_Interval: any
}

export default defineComponent({
  name: "ist-question-answering-component",
  components: {
    Field,
  },
  props: {
    istGroupId: String,
    istGroupType: String,
  },
  setup(props, {emit}) {
    const { istGroupId, istGroupType } = toRefs(props);
    var questionCache = ref([] as Array<IST_Question_Answer>)

    onBeforeMount(() => {
       getIstQuestion(props.istGroupId)
    });

    // Computed property for the v-model
    function inputValues2(index, value) {
        questionCache.value[index].IST_Answer = []

        if(value != null && value != "" && value != undefined)
          questionCache.value[index].IST_Answer.push({ID: value})

        // Start the countdown
        startCountdown(index);
    }

    function setActive(id, index){
      if(istGroupType.value == 'MA'){
        const existingAnswerIndex = questionCache.value[index].IST_Answer.findIndex(answer => answer.ID === id)
        if (existingAnswerIndex !== -1) {
          questionCache.value[index].IST_Answer.splice(existingAnswerIndex, 1);
        }else{
          questionCache.value[index].IST_Answer.push({ID: id})
        }
      }else{
        const existingAnswerIndex = questionCache.value[index].IST_Answer.findIndex(answer => answer.ID === id)
        if (existingAnswerIndex !== -1) {
          questionCache.value[index].IST_Answer = []
        }else{
          questionCache.value[index].IST_Answer = []
          questionCache.value[index].IST_Answer.push({ID: id})
        }
      }

      // Start the countdown
      startCountdown(index);
    }

    function startCountdown(index) {
      var duration = 5; // Initialize countdown
      clearInterval(questionCache.value[index].Count_Down_Interval); // Clear any existing interval

      questionCache.value[index].Count_Down_Interval = setInterval(() => {
        duration -= 1; // Decrease the countdown
        if (duration <= 0) {
          clearInterval(questionCache.value[index].Count_Down_Interval); // Clear any existing interval
          // You can call a function here when the countdown finishes
          // console.log("Countdown finished!", index);
          saveForm(questionCache.value[index])
        }
      }, 1000); // Update every second
    }

    async function saveForm(data_save){
      var saveObj = {}
      saveObj = Object.assign({}, data_save)

      saveObj['Trigger'] = 'C'
      saveObj['Route'] = 'IST_Exam'
      saveObj['Participant_Id'] = "14"
      saveObj['Token'] = "666"

      saveObj['Participant_Answer'] = ""
      if(props.istGroupType == "MA"){
        saveObj['Participant_Answer'] = []
        for(let i=0; i<saveObj['IST_Answer'].length; i++){
          saveObj['Participant_Answer'].push(saveObj['IST_Answer'][i]["ID"])
        }
        saveObj['Participant_Answer'] = JSON.stringify(saveObj['Participant_Answer'])
      }else{
        for(let i=0; i<saveObj['IST_Answer'].length; i++){
          saveObj['Participant_Answer'] = saveObj['IST_Answer'][i]["ID"]
        }
      }
      
      await Services.PostDataXWWW(ApiService, "IST", saveObj, response=>{
        response
      }, err =>{
        err
      })
    };

    function getActive(id, index){
      var result = ''
      var IST_Answer_List = questionCache.value[index].IST_Answer
      for(let i=0; i<IST_Answer_List.length; i++){
          if(IST_Answer_List[i].ID == id)
             result = 'active'
      }
      return result
    }

    async function getIstQuestion(val){
      const mydatat = {
        Trigger					: "R",
        Route					: "IST_Questions",
        IST_Group_Id			: val,
      }

      questionCache.value = []
      await Services.PostDataXWWW(ApiService, "IST", mydatat, response=>{
        questionCache.value = response.data.map(item => ({ 
          ...item, 
          avatarImageUrl: `${ApiService.vueInstance.axios.defaults.baseURL}/IST/storage/${item.IST_Question}`, 
          IST_Choice: item.IST_Choice.map(choice => ({
            ...choice,
            avatarImageUrl: choice.Description, 
            // avatarImageUrl: `${ApiService.vueInstance.axios.defaults.baseURL}/IST/storage/${choice.Description}`, 
            // Add any modifications you need for each choice here
          })),
          IST_Answer: [],
        }));
      }, err =>{
        err
      })
    }

    return {
      questionCache,
      setActive,
      getActive,
      startCountdown,
      inputValues2,
      saveForm,
    };
  },
});
