import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid",
  ref: "verticalWizardRef"
}
const _hoisted_2 = { class: "d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9" }
const _hoisted_3 = { class: "px-6 px-lg-10 px-xxl-15 py-20" }
const _hoisted_4 = { class: "stepper-nav" }
const _hoisted_5 = { class: "stepper-icon w-40px h-40px" }
const _hoisted_6 = { class: "stepper-number" }
const _hoisted_7 = { class: "stepper-label" }
const _hoisted_8 = { class: "stepper-title" }
const _hoisted_9 = { class: "stepper-desc fw-bold" }
const _hoisted_10 = { class: "d-flex flex-row-fluid flex-center bg-white rounded" }
const _hoisted_11 = { class: "d-flex flex-stack pt-10" }
const _hoisted_12 = {
  key: 1,
  type: "submit",
  class: "btn btn-lg btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IstQuestionAnsweringComponent = _resolveComponent("IstQuestionAnsweringComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupCache, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass([{ 'current': _ctx.currentStepIndex === index, 'completed': item.completed }, "stepper-item"]),
              "data-kt-stepper-element": "nav"
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "stepper-line w-40px" }, null, -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("i", {
                  class: _normalizeClass(item.completed ? 'stepper-check fas fa-check' : '')
                }, null, 2),
                _createElementVNode("span", _hoisted_6, _toDisplayString(item.IST_Group_Name), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h3", _hoisted_8, _toDisplayString(item.IST_Group_Id), 1),
                _createElementVNode("div", _hoisted_9, "Soal-soal " + _toDisplayString(item.IST_Questions_Number), 1)
              ])
            ], 2))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("form", {
        class: "py-20 w-100 w-xl-700px px-9",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleStep && _ctx.handleStep(...args)), ["prevent"]))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupCache, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass({ 'current': _ctx.currentStepIndex === index }),
            "data-kt-stepper-element": "content"
          }, [
            (_ctx.currentStepIndex === index)
              ? (_openBlock(), _createBlock(_component_IstQuestionAnsweringComponent, {
                  key: 0,
                  "ist-group-id": item.IST_Group_Id,
                  "ist-group-type": item.IST_Group_Type
                }, null, 8, ["ist-group-id", "ist-group-type"]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128)),
        _createElementVNode("div", _hoisted_11, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "mr-2" }, null, -1)),
          _createElementVNode("div", null, [
            (_ctx.currentStepIndex === _ctx.groupCache.length - 1)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-lg btn-primary me-3",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.formSubmit && _ctx.formSubmit(...args)))
                }, "Submit"))
              : (_openBlock(), _createElementBlock("button", _hoisted_12, "Continue"))
          ])
        ])
      ], 32)
    ])
  ], 512))
}