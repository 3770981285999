import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "pb-10 pb-lg-15" }
const _hoisted_3 = { class: "fw-bolder text-dark" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "row mb-6" }
const _hoisted_6 = { class: "col-lg-8" }
const _hoisted_7 = {
  class: "image-input image-input-outline",
  "data-kt-image-input": "true",
  style: {"background-image":"url(media/avatars/blank.png)"}
}
const _hoisted_8 = {
  key: 1,
  class: "row mb-2 border-bottom border-gray-300",
  "data-kt-buttons": "true"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "row mb-6" }
const _hoisted_11 = { class: "col-lg-8" }
const _hoisted_12 = {
  class: "image-input image-input-outline",
  "data-kt-image-input": "true",
  style: {"background-image":"url(media/avatars/blank.png)"}
}
const _hoisted_13 = {
  key: 2,
  class: "row mb-2 border-bottom border-gray-300",
  "data-kt-buttons": "true"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "fw-bolder fs-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionCache, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, "No. " + _toDisplayString(item.IST_Order), 1),
          (_ctx.istGroupType=='PSO')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: "image-input-wrapper w-125px h-125px",
                        style: _normalizeStyle(`background-image: url(${item.avatarImageUrl})`)
                      }, null, 4)
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createBlock(_component_Field, {
                key: 1,
                as: "textarea",
                class: "form-control form-control-lg form-control-solid",
                name: _ctx.istGroupId+'-'+item.IST_Question_Id,
                placeholder: "",
                value: item.IST_Question,
                disabled: ""
              }, null, 8, ["name", "value"]))
        ]),
        (_ctx.istGroupType == 'OW')
          ? (_openBlock(), _createBlock(_component_Field, {
              key: 0,
              type: "text",
              class: "form-control form-control-lg form-control-solid",
              name: _ctx.istGroupId+'-'+item.IST_Question_Id+'ans',
              value: "",
              onInput: (e)=>_ctx.inputValues2(index, e.target.value),
              placeholder: ""
            }, null, 8, ["name", "onInput"]))
          : (_ctx.istGroupType == 'PSO')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.IST_Choice, (item2, index2) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index2,
                      class: "col-6",
                      style: {"margin-bottom":"20px"}
                    }, [
                      _createElementVNode("label", {
                        class: _normalizeClass(["btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4", _ctx.getActive(item2.ID, index)])
                      }, [
                        _createVNode(_component_Field, {
                          type: "radio",
                          class: "btn-check",
                          name: _ctx.istGroupId+'-'+item.IST_Question_Id+'-'+item2.ID,
                          value: item2.ID,
                          onClick: ($event: any) => (_ctx.setActive(item2.ID, index))
                        }, null, 8, ["name", "value", "onClick"]),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", {
                                class: "image-input-wrapper w-125px h-125px",
                                style: _normalizeStyle(`background-image: url(${item2.Description})`)
                              }, null, 4)
                            ])
                          ])
                        ])
                      ], 2)
                    ]))
                  }), 128))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.IST_Choice, (item2, index2) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index2,
                      class: "col-6",
                      style: {"margin-bottom":"20px"}
                    }, [
                      _createElementVNode("label", {
                        class: _normalizeClass(["btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4", _ctx.getActive(item2.ID, index)])
                      }, [
                        _createVNode(_component_Field, {
                          type: "radio",
                          class: "btn-check",
                          name: _ctx.istGroupId+'-'+item.IST_Question_Id+'-'+item2.ID,
                          value: item2.ID,
                          onClick: ($event: any) => (_ctx.setActive(item2.ID, index))
                        }, null, 8, ["name", "value", "onClick"]),
                        _createElementVNode("span", _hoisted_15, _toDisplayString(item2.Description), 1)
                      ], 2)
                    ]))
                  }), 128))
                ])
              ]))
      ], 64))
    }), 128))
  ]))
}